@import '~common/styles/variables';
@import '~common/styles/mixins';

.product-context-inputs {
  @include flex-cols;
  @include flex-wrap;

  .with-label {
    @include flex-fill;
    color: $dark-gray;

    margin: 0 10px;

    &:first-of-type {
      margin-left: $content-base-left-padding;

      @include phone {
        margin-left: $content-base-left-padding-phone;
      }
    }

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    label {
      margin: 5px 0;
    }
  }
}
