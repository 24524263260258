@import '~common/styles/variables';
@import '~common/styles/mixins';

@mixin colored($color) {
  > .header {
    background-color: $color;
    color: white;
  }

  > .body {
    border: 2px solid $color;
    border-top: none;
  }
}

.imo-rider-card {
  @include colored($dark-red);

  > .header {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;

    padding: 12px 0 8px 10px;
    font-size: 20px;

    @include phone {
      font-size: 16px;
      padding: 8px 0 6px 12px;
    }

    .name {
      font-weight: bold;
    }
  }

  > .body {
    background-color: white;
    display: grid;
    padding: 20px;
    grid-gap: 10px;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;

    @include phone {
      padding: 10px;
    }

    input[type="text"]::placeholder {
      color: $blueish;
    }
  }
}

.imo-rider-card.imo-rider-card-primary {
  @include colored($green);
}