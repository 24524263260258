@import '~common/styles/variables';
@import '~common/styles/mixins';

.imo-tab-view {
  .tab-view-navigation {
    @include flex-cols;
    @include flex-justify-center;
    border-bottom: 1px solid $medium-gray;

    a {
      font-size: 14px;
      position: relative;
      top: 1px;
      border-bottom: 1px solid $medium-gray;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
      padding: 8px 18px 4px 18px;
      text-decoration: none;
      font-weight: bold;
      color: $blue;
      user-select: none;

      .with-tooltip {
        align-items: center;

        .info-icon {
          display: none;
          margin-bottom: 0;
        }
      }

      &.active {
        border: 1px solid $medium-gray;
        border-bottom: 1px solid $beige;
        color: $purple;

        .info-icon {
          display: initial;
        }
      }
    }
  }
}