@import '~common/styles/variables';
@import '~common/styles/mixins';

.card {
  > .header {
    .number {
      width: 24px;
      height: 24px;
      @include flex-center;

      @include phone {
        height: 16px;
        width: 16px;
        margin: 5px 10px 5px $content-base-left-padding-phone;
        // used for vertical centering, due to lack of better alternatives
        padding-top: 1.5px;
      }

      @include tablet {
        // used for vertical centering, due to lack of better alternatives
        padding-top: 1px;
      }

      margin: 10px 10px 10px $content-base-left-padding;

      background-color: white;
      border-radius: 50%;

      color: $dark-gray;
      line-height: 24px;
      text-align: center;
      vertical-align: middle;
    }

    .name {
      @include flex-fill;

      text-transform: uppercase;
      color: white;
      margin: 0;

      @include phone {
        // used for vertical centering, due to lack of better alternatives
        padding-top: 0.5px;
      }

      @include tablet {
        // used for vertical centering, due to lack of better alternatives
        padding-top: 1px;
      }

      @include tablet-landscape {
        // used for vertical centering, due to lack of better alternatives
        padding-top: 1px;
      }

    }

    .number,
    .name {
      font-size: 12px;
      font-weight: bold;

      @include phone {
        line-height: 12px;
        font-size: 12px;
      }

      @include tablet {
        line-height: 14px;
        font-size: 14px;
      }

      @include tablet-landscape {
        // used for vertical centering, due to lack of better alternatives
        padding-top: 1px;
      }
    }
  }

  > .body {
    padding: 20px 20px 0px 20px;

    @include phone {
      padding: 10px 0px 0px 0px;
    }

    .options {
      @include flex-cols;
      @include flex-wrap;
      @include phone {
        @include flex-space-between;
      }

      .with-label {
        @include desktop {
          @include flex-fill;
        }

        @include tablet {
          @include flex-fill;
        }


        @include phone {
          width: calc(50% - 2*#{$labeled-input-margins});
        }
      }

      .with-label {
        margin-left: $labeled-input-margins;
        margin-right: $labeled-input-margins;
      }
    }

    > .details {
      padding-top: 10px;
      padding-bottom: 20px;

      @include phone {
        padding-bottom: 10px;
      }

      .base-benefit {
        margin-top: 10px;
        font-weight: bold;
        font-size: 18px;

        @include phone {
          margin-left: $content-base-left-padding-phone;
        }
      }

      .hurdle-rate {
        margin-top: 5px;
        font-size: 13px;

        .up-arrow-icon {
          float: left;
          width: 25px;
          height: 30px;

          @include phone {
            height: 40px;
          }

          margin-right: 5px;
        }
      }
    }

    .additional-premium-label {
      margin-left: 10px;
      margin-top: 10px;
      margin-bottom: 0px;
    }
  }

  > .footer {
    padding: 15px 20px 15px $content-base-left-padding;

    @include phone {
      padding: 10px 10px 10px $content-base-left-padding-phone;
    }

    .annual-income, .initial-premium {
      font-size: 24px;

      @include tablet {
        font-size: 32px;
      }

      @include phone {
        font-size: 20px;
      }
    }

    .money-value {
      font-weight: bold;
    }
  }

  @mixin colored($color, $icon) {
    > .header {
      background-color: $color;
    }

    > .body .details,
    > .header .number {
      color: $color;
    }

    > .footer {
      background-color: $color;
      color: white;
    }

    .up-arrow-icon {
      @include icon($icon, 25px);
      margin-left: $content-base-left-padding;

      @include phone {
        margin-left: $content-base-left-padding-phone;
      }
    }
  }

  &.card-evaluated:first-of-type {
    @include colored($green, '~annuity_lab/assets//icons/up-arrow-green.svg');
  }

  &.card-evaluated {
    @include colored($dark-red, '~annuity_lab/assets//icons/up-arrow-purple.svg');
  }
}
