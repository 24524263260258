@import '~common/styles/mixins';
@import '~common/styles/variables';

.imo-new-model-form {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;

  .title-container {
    @include flex-cols;
    @include flex-justify-center;

    .title {
      text-align: center;
      color: $purple;
      font-size: 18px;
      font-weight: bold;
    }
  }

  .button-container {
    @include flex-rows;
    @include flex-justify-center;
    @include flex-align-center;

    .error {
      color: $red;
      text-align: center;
    }

    .create-new-model-btn {
      width: 40%;
      font-size: 24px;
      font-weight: bold;
      padding-top: 20px;
      padding-bottom: 20px;

      @include phone {
        width: 80%;
      }
    }

    .reset-riders-btn {
      font-size: 18px;
      margin-top: 10px;
      background-color: $beige;
      color: $blue;
    }
  }
}