@import '~common/styles/mixins';

.loading-indicator {
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  color: $white;
  width: 100%;
  @include flex-center;

  .loading-icon {
    position: relative;
    width: 249px;
    height: 249px;

    div {
      width: 100%;
      height: 100%;
      position: absolute;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-timing-function: cubic-bezier(0.55, 0.06, 0.68, 0.19);
    }

    .loading-1 {
      @include icon('~annuity_lab/assets//icons/lbc_sync_25.svg', 249px);
      animation-name: loading-1;
    }
    .loading-2 {
      @include icon('~annuity_lab/assets//icons/lbc_sync_50.svg', 249px);
      animation-name: loading-2;
    }
    .loading-3 {
      @include icon('~annuity_lab/assets//icons/lbc_sync_75.svg', 249px);
      animation-name: loading-3;
    }
    .loading-4 {
      @include icon('~annuity_lab/assets//icons/lbc_sync_100.svg', 249px);
      animation-name: loading-4;
    }
  }

}

@keyframes loading-1 {
  0% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  75% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes loading-2 {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  75% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes loading-3 {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  75% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes loading-4 {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  75% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}
