@import '~common/styles/mixins';
@import '~common/styles/variables';

.hint {
  color: $blue;
  margin-top: -5px;
  margin-bottom: 30px;
  vertical-align: middle;

  @include phone {
    margin-bottom: 10px;
  }

  .hint-text {
    font-size: 26px;
    font-weight: bold;
    display: inline-block;

    width: calc(100% - 80px);
    vertical-align: middle;

    @include phone {
      font-size: 12px;
      width: calc(100% - 45px);
    }
  }

  > .arrow-down-icon {
    display: inline-block;
    width: 75px;
    height: 55px;
    @include icon('~annuity_lab/assets//icons/arrow_down_blue.svg', 30px);

    vertical-align: middle;

    @include phone {
      height: 30px;
      width: 30px;
      margin-right: 10px;
    }
  }
}