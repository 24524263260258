@import '~common/styles/mixins';
@import '~common/styles/variables';

.imo-riders-modeled {
  .title {
    font-size: 22px;
    color: $less-dark-gray;
    font-weight: normal;
    text-align: left;
  }

  .riders-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;

    @include phone {
      grid-template-columns: 1fr;
    }
  }
}