@import '~common/styles/mixins';
@import '~common/styles/variables';

.premium-comparison {
  width: 100%;

  > .hint {
    text-align: center;
    font-size: 17px;
    color: #666;

    margin-top: -10px;
    margin-bottom: 20px;
  }

  > .products {
    @include flex-rows;

    .small-product-card {
      margin-bottom: 10px;
    }

    .comparable {
      @include flex-cols;

      .product-order-1 {
        margin-right: 15px;
      }
      .product:only-child {
        margin-right: 0;
      }

      @include phone {
        @include flex-rows;

        .product {
          margin-right: 0;
        }
      }
    }

    .product {
      @include flex-fill;

      border-radius: 8px;
      overflow: hidden;
      color: white;

      &.not-in-comparison {
        @include phone {
          order: 1;
          flex-basis: auto;
        }
      }

      &.in-comparison {
        @include phone {
          flex-basis: auto;
        }
      }
    }

    .small-product-card .content {
      border: 0;
    }

    .product-order-0 {
      .small-product-card {
        @include card-color($green);
      }
    }
    .product-order-1 {
      .small-product-card {
        @include card-color($dark-red);
      }
    }

    .product-order-2 {
      .small-product-card {
        @include card-color($light-red);
      }
    }
  }
}
