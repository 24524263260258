@import '~common/styles/variables';
@import '~common/styles/mixins';
@import '~common/styles/charts';

.plus-riders-comparisons-chart {
  @include base-line-chart;

  .chart-title {
    text-align: center;
    color: $less-dark-gray;
    font-weight: 400;

    @include mobile {
      font-size: 18px;
    }
  }

  .circle-container-path-base {
    width: 20px;
    height: 20px;
    position: absolute;
    @include icon('~annuity_lab/assets/icons/asterisk_green.svg', 20px);
  }

  .circle-container-path-secondary {
    width: 20px;
    height: 20px;
    position: absolute;
    @include icon('~annuity_lab/assets/icons/asterisk_red.svg', 20px);
  }
}
