@import '~common/styles/variables';
@import '~common/styles/mixins';

.plus-tab-view {
  .tab-view-navigation {
    @include flex-cols;
    @include flex-space-between;
    border-bottom: 1px solid $medium-gray;

    div {
      @include flex-cols;
      @include flex-justify-center;

      position: relative;
    }

    a {
      font-size: 14px;
      position: relative;
      top: 1px;
      border-bottom: 1px solid $medium-gray;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
      padding: 8px 18px 4px 18px;
      text-decoration: none;
      font-weight: bold;
      color: $blue;
      user-select: none;

      &.active {
        border: 1px solid $medium-gray;
        border-bottom: 1px solid $beige;
        color: $purple;
      }
    }
  }

  .tab-view-rotues {
    padding-top: 15px;
  }
}