@import '~common/styles/variables';
@import '~common/styles/mixins';

.save-withdrawal-values-dialog {
  width: 550px;

  @include phone {
    width: 400px;
  }

  @media only screen and (max-width: 400px) {
    width: 370px;
  }

  &.panel .header {
    background-color: $purple;
  }

  .body,
  .footer {
    @include flex-rows;
  }

  &.panel .body {
    .title-row {
      @include flex-cols;
      @include flex-space-between;

      span {
        text-transform: uppercase;
        font-size: 9px;
        font-weight: bold;

        width: 70px;

        &.age-ranges {
          width: 118px;

          align-self: flex-end;
          text-align: center;
        }
      }
    }

    .value-inputs__row {
      @include flex-cols;
      @include flex-space-between;

      .range-container {
        @include flex-cols;
        @include flex-align-center;
      }

      .input-container {
        @include flex-cols;
        @include flex-align-center;
      }

      @include phone() {
        @include flex-space-evenly;
      }

      input {
        @include input-font-color($blueish);

        margin: 5px 2px;
        min-width: 30px;
        width: 30px;
        font-size: 13px;

        @include phone {
          padding: 6px;
          margin: 5px 0;
          min-width: 33px;
          width: 33px;
        }
      }
    }

    @include phone {
      padding: 10px 5px;
    }
  }

  .card-note {
    margin-bottom: 10px;

    h5 {
      font-size: 9px;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 5px;
    }

    textarea {
      width: 100%;

      font-family: "Gotham Book";
      color: $blueish;
      font-size: 13px;
      -webkit-appearance: none;

      border-radius: $small-border-radius;
      border: 1px solid $light-gray;

      background-color: white;

      line-height: 20px;
      padding: 7px 10px 6px 10px;

      resize: none;
    }
  }

  .errors {
    margin: 5px 0;
    font-size: 15px;
    color: $red;
    font-weight: bold;
  }

  .save-button {
    @include flex-fill;
  }
}
