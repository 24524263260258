@import '~common/styles/variables';
@import '~common/styles/mixins';

.admin-app {
  $admin-app-border-radius: $border-radius;

  @include flex-rows;
  @include flex-justify-center;

  max-width: 1440px;
  height: 100%;
  margin: 10px auto 20px;
  padding: 20px 40px 40px;
  background-color: $beige;
  border-top-left-radius: $admin-app-border-radius;
  border-top-right-radius: $admin-app-border-radius;
  margin: 10px auto 20px;

  .fia-uploader {
    width: 600px;
    margin: 0 auto;
  }
}
