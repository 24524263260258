@import '~common/styles/variables';
@import '~common/styles/mixins';

.imo-carry-forward-view {
  display: grid;
  grid-template-rows: 1fr;
  grid-gap: 24px;
  padding-top: 18px;

  .inputs-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;

    @include mobile {
      label {
        font-size: 10px;
      }
    }

    @include phone {
      grid-template-columns: 1fr;
      grid-gap: 10px;
    }
  }

  .cards-summary {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    text-align: center;
  }
}