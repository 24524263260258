@import '~common/styles/variables';
@import '~common/styles/mixins';

.modal-dialog-overlay {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  @include phone {
    padding: 0 30px;
  }

  background-color: rgba(black, 0.8);

  @include flex-center;
}

.modal-dialog {
  width: 400px;

  @include phone {
    width: 100%;
  }

  &.panel > .body {
    @include phone {
      padding: 20px;
    }
  }

  &:focus {
    outline: none;
  }

  &.panel {
    button.btn {
      font-weight: bold;
    }
    .header {
      background-color: $purple;

      h4 {
        @include flex-fill;
        font-size: 12px;
        font-weight: bold;
        padding: 15px 20px;
      }
    }
  }
}
