.card {
  > .footer {
    .initial-premium {
      .required-premium {
        font-size: 85%;
        font-weight: normal;
      }
    }
  }
}
