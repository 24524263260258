@import '~common/styles/variables';
@import '~common/styles/mixins';

.Select {
  &__control {
    @include flex-cols;
    @include flex-space-between;
    @include flex-align-center;

    height: 35px;
    border-radius: 4px;
    border: 1px solid $light-gray;
    box-shadow: 0;
    background-color: $white;
    outline: none;
    padding-right: 8px;
    max-width: 100%;

    &.Select__control--menu-is-open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .Select__value-container {
      padding: 2px 8px;
      position: relative;
      flex-wrap: wrap;
      max-width: 100%;

      font-size: 14px;

      @include mobile {
        font-size: 16px;
      }

      .Select__single-value {
        max-width: initial;
      }

      .Select__placeholder {
        color: $blueish;
        text-overflow: clip;
        white-space: nowrap;
      }
    }

    .Select__indicators {
      cursor: pointer;
      width: 0;
      height: 0;
      border-color: $blueish transparent transparent;
      border-style: solid;
      border-width: 5px 5px 2.5px;

      .Select__indicator-separator {
        display: none;
      }
    }
  }

  &__menu {
    background-color: $white;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 8px;
    margin-top: 8px;

    box-shadow: 0 3px 8px rgba(0,0,0,0.1);
    position: absolute;
    z-index: 10;
    top: 26px;
    border-radius: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 1px solid $light-gray;

    .Select__menu-list {
      max-height: 200px;
      overflow: auto;
      box-sizing: border-box;
      position: relative;

      .Select__option {
        box-sizing: border-box;
        display: block;
        padding: 8px 12px;
        border-bottom: 1px solid $light-gray;
        cursor: pointer;
        color: $blueish;

        &:last-child {
          border-bottom: 0;
        }

        &--is-focused {
          background-color: $light-blue;
        }
      }
    }
  }
}