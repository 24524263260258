@import '~common/styles/mixins';
@import '~common/styles/variables';

.select-rider-view {
  color: $dark-gray;

  min-height: 100%;

  @include flex-rows;

  .select-rider-content {
    padding: 200px $content-base-left-padding;

    @include phone {
      padding: 40px $content-base-left-padding-phone;
    }

    @include flex-rows;
    @include flex-align-center;
    width: 100%;

    .heading {
      font-size: 3.2rem;
      font-weight: lighter;
      margin-bottom: 60px;

      @include phone {
        font-size: 2.6rem;
      }
    }

    .button-container {
      @include flex-cols;
      @include flex-justify-center;
      width: 100%;

      .btn {
        width: 50%;

        &:first-child {
          margin-right: 20px;
        }
      }
    }

    .select-rider-error {
      color: $red;
    }
  }
}