@import '~common/styles/variables';
@import '~common/styles/mixins';

.small-product-card {
  @include flex-fill;

  @include phone {
    background-color: white;
    border-width: 2px;
    border-style: solid;
    margin-bottom: 10px;
  }

  .card .header {
    display: block;
    padding: 8px 12px;
    font-size: 12px;
    line-height: 15px;

    @include ellipsis;

    @include phone {
      text-align: center;
    }

    > .product-name {
      text-transform: uppercase;
      font-weight: bold;
    }

    > .product-stats {
      margin-left: 5px;
    }
  }

  > .card .body {
    padding: 20px 0 0 0;

    @include phone {
      padding: 17px 0 0;
    }

    font-size: 48px;
    font-weight: bold;
  }

  > .card .remark {
    padding: 10px;
    font-size: 18px;
    border: 0;
    padding: 5px 10px 17px 10px;

    @include phone {
      padding: 7px 10px 12px;
      border-bottom: none;
      box-shadow: none;
    }
  }

  .body,
  .remark {
    text-align: center;
  }

  .content {
    @include flex-cols;
    @include flex-align-center;
    @include flex-justify-center;

    .main {
      width: 100%;
      @include flex-value(1);
      @include flex-grow(2);
      overflow: hidden;
    }

    .aside {
      @include flex-value(2);
    }

    border-left: 2px solid white;
    border-right: 2px solid white;
    border-bottom: 2px solid white;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    @include phone {
      border-left: none;
      border-right: none;
    }
  }

  @include tablet {
    > .card .header {
      padding: 16px 16px 14px;
      font-size: 16px;
    }

    > .card .body {
      font-size: 56px;
    }

    > .card .remark {
      font-size: 22px;
    }
  }

  .card.large-fonts .header {
    @include desktop {
      padding: 12px;
      font-size: 18px;
      line-height: 20px;
      text-align: center;
    }

    @include tablet-landscape {
      padding: 16px 16px 14px;
      font-size: 16px;
      line-height: 18px;
    }
  }

  .card.large-fonts .body {
    @include desktop {
      font-size: 72px;
    }

    @include tablet-landscape {
      font-size: 56px;
    }
  }

  .card.large-fonts .remark {
    @include desktop {
      font-size: 28px;
    }

    @include tablet-landscape {
      font-size: 22px;
    }
  }
}
