@import '~common/styles/mixins';
@import '~common/styles/variables';

$arrow-size: 12px;

.info-balloon {
  position: relative;
  margin-top: 30px;
  border-radius: $border-radius;
  padding: 10px;
  color: $beige;

  @include phone {
    margin-top: 12px;
    box-shadow: none;
  }

  .arrow {
    position: absolute;

    width: 0;
    height: 0;

    border-left: $arrow-size solid transparent;
    border-right: $arrow-size solid transparent;

    &.top {
      top: -$arrow-size;
      border-bottom: $arrow-size solid $green;
    }

    &.bottom {
      bottom: -$arrow-size + 1px;
      border-top: $arrow-size solid $green;
    }
  }
}
