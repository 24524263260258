@import '~common/styles/mixins';
@import '~common/styles/variables';

.no-products-view {
  .icon-container {
    padding-top: 10px;

    div {
      @include flex-fill;
    }

    .icon-up {
      height: 50px;

      @include phone {
        height: 40px;
      }

      @include icon('~annuity_lab/assets//icons/arrow_blue.svg', auto);
    }
  }

  .content {
    padding: 40px;

    @include phone {
      padding: 15px;
      text-align: center;

      h1 {
        font-size: 34px;
      }
    }

    em {
      font-style: normal;
      font-weight: bold;
      color: $blue;
      @include phone {
        display: block;
      }
    }
  }

  @include tablet {
    .icon-container {
      padding-top: 30px;

      .icon-up {
        height: 70px;
        background-size: 70px auto;
      }
    }
  }

  .btn-compare {
    margin-top: 10px;
    margin-bottom: 25px;
    width: 100%;
    font-weight: bold;
  }
}
