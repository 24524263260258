@import '~common/styles/variables';
@import '~common/styles/mixins';
@import '~common/styles/fonts';

@mixin column_widths($min-table-width, $percentage-small-table-column) {
  $small-table-column-width: $min-table-width * $percentage-small-table-column / 100;
  $medium-table-column-width: $small-table-column-width * 2;
  $large-table-column-width: $small-table-column-width * 4;

  td.small {
    width: $small-table-column-width;
  }

  td.medium {
    min-width: $medium-table-column-width;
  }

  td.large {
    min-width: $large-table-column-width;
  }
}

.imo-standard-view-table {
  overflow: auto;

  -webkit-overflow-scrolling: touch;

  $min-table-width: 700px;
  $percentage-small-table-column: 100 / ((2 * 1) + (4 * 4)); // 2 small and 4 large columns when there are 2 riders (5.56)

  table {
    width: 100%;
    font-size: 13px;
    min-width: $min-table-width;
    border-collapse: collapse;
    border-spacing: 1px;

    @include mobile {
      font-size: 12px;
    }

    span {
      display: inline-block;
    }

    thead {
      font-weight: bold;

      th {
        text-align: center;
        padding-left: 8px;
        padding-right: 16px;
        font-size: 10px;
      }
    };

    tbody {
      tr {
        opacity: 0.5;

        &.highlighted {
          opacity: 1;
        }

        &:not(:first-child) {
          background-color: $white;
          border-bottom: 1px solid $beige;

        }

        @include column_widths($min-table-width, $percentage-small-table-column);
      }
    }

    td {
      font-family: 'Gotham Book';
      padding-right: 16px;
    }


    .ab-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 4px;
    }

    .table-rider-name {
      text-align: center;
      font-size: 10px;
      color: $white;
      font-weight: bold;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
      padding: 4px 0 2px 0;

      @include mobile {
        font-size: 9px;
      }
    }

    .rider-a-text {
      background-color: $green;
    }

    .rider-b-text {
      background-color: $dark-red;
    }

    .rider-value {
      color: $white;
      padding-left: 4px;
      padding-top: 2px;
    }

    .money-container {
      text-align: right;
      padding-right: 4px;
      display: inline-block;
      width: 100%;
    }

    .age-container, .contract-container {
      min-width: $min-table-width * 4 / 100;
      padding-left: 4px;
    }
  }
}

.imo-standard-view-table.one-rider {
  width: 60%;
  margin-left: 20%;
  margin-right: 20%;


  @include mobile {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  table {
    $min-table-width: 500px;
    $percentage-small-table-column: 100 / ((2 * 1) + (4 * 2)); // 2 small and 4 medium columns when one rider (10)

    min-width: $min-table-width;

    tbody tr {
      @include column_widths($min-table-width, $percentage-small-table-column);
    }

    .ab-container {
      grid-template-columns: 1fr;
    }
  }
}