@import '~common/styles/mixins';
@import '~common/styles/variables';

.imo-client-information {
  .title {
    font-size: 22px;
    color: $less-dark-gray;
    font-weight: normal;
    text-align: left;
  }

  .inputs-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;

    @include phone {
      grid-template-columns: 3fr;
    }

    @include mobile {
      label {
        font-size: 10px;
      }
    }

    input[type="text"]::placeholder {
      color: $blueish;
    }
  }
}