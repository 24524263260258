@import '~common/styles/mixins';
@import '~common/styles/variables';

.comparison-controls {
  @include mobile-footer-controls;

  .controls {
    @include flex-cols;
    .new-button {
      background-color: white;
      color: $blue;
    }
    .remove-button {
      background-color: $dark-red;
      color: white;
    }
  }

  .controls.desktop {
    @include flex-justify-end;

    button {
      width: 150px;
      font-size: 9px;
      font-weight: bold;
      padding: 8px;
    }

    button + button {
      margin-left: 10px;
    }
  }

  .controls.mobile {
    .btn {
      border-radius: 8px;
    }
    .btn-block {
      margin: 11px 6px;;
    }
  }

  .controls .short-label {
    display: none;
  }

  @include tablet-landscape {
    .controls.mobile {
      @include flex-cols;
    }
  }

  @include phone {
    .controls .short-label {
      display: block;
    }
    .controls .full-label {
      display: none;
    }
  }
}

.ReactModal__Overlay {
  &.desktop {
    @include mobile {
      display: none;
    }
  }
  &.mobile {
    @include desktop {
      display: none;
    }

    // show .mobile on tablet landscape as
    // @tablet-landscape and @desktop mixins are overlapping
    @include tablet-landscape {
      @include flex-rows;
    }
  }
}

@include mobile {
  .comparison-controls.desktop {
    display: none;
  }
}

@include desktop {
  .comparison-controls.mobile {
    display: none;

    // show .mobile on tablet landscape as
    // @tablet-landscape and @desktop mixins are overlapping
    @include tablet-landscape {
      display: block;
    }
  }
}
