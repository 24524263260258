@import '~common/styles/mixins';
@import '~common/styles/variables';

.new-comparison-view {
  padding: 40px;

  @include phone {
    text-align: center;
    padding: 15px;
  }

  h1 {
    @include phone {
      font-size: 32px;
    }
  }

  .multiple-selects-container {
    @include flex-cols;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    @include flex-wrap;

    @include phone {
      @include flex-rows;

      padding-top: 10px;
      padding-bottom: 10px;
    }

    .card-select {
      flex: 1;
      margin-top: 0;

      @include phone {
        margin-top: 10px;
      }
    }
  }

  .single-select-container {
    width: 50%;
    padding-top: 20px;
    padding-bottom: 20px;

    @include phone {
      width: 100%;
      @include flex-fill;
      text-align: left;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  .btn-compare {
    margin-top: 30px;
    width: 100%;
    font-weight: bold;
  }
}
