@import '~common/styles/variables';
@import '~common/styles/mixins';
@import '~common/styles/charts';

.more-income-chart {
  @include base-line-chart;
  @include plus-carry-forward-chart;

  .title-container {
    .chart-line-info {
      p:last-of-type {
        &::before {
          background-color: $purple;
        }
      }
    }
  }

  .c3-event-rects {
    display: none;
  }

  .rect-above, .rect-below, .rect-below-2 {
    position: absolute;
  }

  .rect-below, .rect-below-2 {
    background-color: $blue;
    opacity: 0.6;
  }

  .rect-above {
    background-color: $purple;
    opacity: 0.6;
  }
}

