$site-max-width: 1440px;

// Colors
$purple: #403a60;

$light-blue: #cbf2f5;
$medium-blue: #6697a2;
$blue: #30cdd7;
$blueish: #005264;
$navy-blue: #174677;
$dark-blue: #005265;
$darkest-blue: #00181e;

$green: #279989;

$dark-red: #672146;
$red: #ff0000;
$light-red: #a83d72;

$dark-gray: #4d4f53;
$less-dark-gray: #6b6d70;
$gray: #9e9e9e;
$medium-gray: #c8c8c8;
$light-gray: #dcdcdc;

$beige: #eff0ee;

$white: #ffffff;

// Common measurement units

$border-radius: 10px;
$small-border-radius: 4px;
$menu-height: 40px;

$content-base-left-padding: 20px;
$content-base-left-padding-phone: 10px;
$labeled-input-margins: $content-base-left-padding-phone;
