@import '~common/styles/mixins';
@import '~common/styles/variables';

.imo-model {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;

  .title-container {
    @include flex-cols;
    @include flex-justify-center;

    .title {
      text-align: center;
      color: $purple;
      font-size: 18px;
      font-weight: bold;
      margin-right: 6px;
    }
  }
}