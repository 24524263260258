@import '~common/styles/mixins';
@import '~common/styles/variables';

.edit-rider-modal.modal-dialog.ReactModal__Content  {
  .header {
    @include flex-cols;
    @include flex-space-between;
    background-color: $dark-red;
    padding-left: 5px;

    &.primary {
      background-color: $green;
    }
  }

  .body {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 6px;
  }

  .footer {
    .error {
      color: $red;
    }

    .update-rider-button {
      color: $white;
      font-weight: bold;
      background-color: $blue;
      border-radius: $border-radius;
      width: 100%;
      text-align: center;
      height: 42px;
      font-size: 16px;
      padding: 6px;
    }
  }
}