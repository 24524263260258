@import '~common/styles/mixins';
@import '~common/styles/variables';

.plus-carry-forward-view {
  display: grid;

  .input-message-container {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 22px;
    padding: 0 10px;
    align-items: center;

    @include phone {
      grid-template-columns: 1fr;
      grid-gap: 0;
    }

    .message {
      font-size: 22px;
      color: $dark-gray;
    }
  }
}