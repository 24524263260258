@import '~common/styles/variables';
@import '~common/styles/mixins';

.info-dialog {
  &.panel .header {
    background-color: $blueish;
  }

  .body {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}
