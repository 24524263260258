@import '~common/styles/mixins';
@import '~common/styles/variables';

.cards-plus-view {
  color: $dark-gray;

  min-height: 100%;

  @include flex-rows;

  .product-count {
    margin: 15px 0 15px $content-base-left-padding;

    @include phone {
      margin: 5px 0 5px $content-base-left-padding-phone;
    }
  }

  .product-context-inputs {
    margin-bottom: 35px;

    @include phone {
      margin-bottom: 17px;
    }
  }

  .rider-cards-controls {
    @include mobile {
      display: none;
    }
  }

  .card-plus + .card-plus {
    margin-top: 15px;

    @include phone {
      margin-top: 10px;
    }
  }
}