@import '~common/styles/mixins';

.imo-standard-view {
  display: grid;
  grid-template-rows: 1fr;
  grid-gap: 24px;

  .cards-summary {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    padding-top: 18px;
    text-align: center;
  }

  .info-text {
    margin: 0;

    font-size: 9px;
    text-align: center;
    font-family: "Gotham Book";
  }
}