@import '~common/styles/variables';
@import '~common/styles/mixins';

.input-with-icon {
  position: relative;

  border: 3px solid transparent;
  border-radius: $border-radius;

  &.invalid {
    border-color: #a83d72;
  }

  .input-icon {
    position: absolute;
    top: 0px;
    left: 0px;

    width: 35px;
    height: 100%;

    background-position: right;
  }

  input {
    padding-left: 40px;

    // XXX: Ugly IE11 fix
    // For explanation take a look at the `app.css.scss` and the style that this overrides.
    width: calc(100% - 52px);
  }
}
