.disclaimer {
  margin: 20px 0 10px;
  font-size: 9px;
  text-align: center;
  font-family: "Gotham Book";

  p {
    margin: 0;
  }
}
