@import '~common/styles/mixins';
@import '~common/styles/variables';

.phone-footer {
  position: relative;
  z-index: 2;
  @include desktop {
    display: none;
  }

  @include tablet-landscape {
    display: block;
  }

  &.hidden {
    display: none;
  }

  width: 100%;
  background-color: $purple;
}
