@import '~common/styles/variables';
@import '~common/styles/mixins';

.save-comparison-dialog {
  &.panel .header {
    background-color: $purple;
  }

  .body,
  .footer {
    @include flex-cols;
  }

  &.panel .body {
    input {
      @include input-font-color($blueish);
    }

    @include phone {
      padding: 20px;
    }
  }

  .save-button {
    @include flex-fill;
  }
}
