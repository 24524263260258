@import '~common/styles/variables';
@import '~common/styles/mixins';

.attachment {
  margin-bottom: 30px;

  h1 {
    color: $medium-gray;
    font-size: 20px;
    font-weight: bold;
  }

  .link {
    display: inline-block;

    .icon-link {
      width: 40px;
      height: 40px;
      display: inline-block;
      vertical-align: middle;
      @include icon('~annuity_lab/assets/icons/attachment_icon.svg', 35px);
    }
  
    a {
      vertical-align: middle;
      text-decoration: none;
      display: inline-block;
      color: $blue;
      font-weight: bold;
      margin-left: 5px;
    }
  }
}
