@import '~common/styles/variables';
@import '~common/styles/mixins';

.message-dialog {
  .header {
    background-color: $purple;
  }

  .body {
    @include flex-cols;
  }

  .footer {
    @include flex-cols;

    button {
      @include flex-fill;
    }

    button + button {
      margin-left: 10px;
    }
  }
}
