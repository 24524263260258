@import '~common/styles/variables';

.rider-select {
  .Select {
    &__menu {
      .Select__menu-list {
        .Select__option {
          &:last-child {
            border-bottom: 1px solid $light-gray;
          }
        }
      }
    }
  }
}