@import '~common/styles/mixins';
@import '~common/styles/variables';

.login-view {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 15px 40px 15px;
  color: $beige;

  @include phone {
    height: 100%;
    padding-left: 18px;
    padding-right: 18px;
  }

  .body {
    font-family: "Gotham Extra Light";
    font-size: 32px;
    color: $beige;
    margin-top: 30px;
    text-align: center;

    .tools-label {
      font-family: Gotham;
    }

    @include phone {
      margin-top: 0;
      font-size: 30px;
      .tip {
        display: inline;
      }

      .tools-label {
        &:before {
          content: ' ';
        }
        display: inline;
      }
    }
  }

  .controls {
    @include flex-rows;
    @include flex-align-center;

    .btn-group {
      display: grid;
      grid-template-columns: auto auto auto auto;

      @include mobile {
        grid-template-columns: auto auto;
      }
    }

    .btn {
      font-weight: bold;
      margin: 7px 10px;
      width: 180px;
      height: 56px;

      @include phone {
        padding-top: 8px;
        padding-bottom: 8px;
      }
    }
  }

  .errors {
    font-size: 16px;
    line-height: 110%;
    text-align: center;
    ul {
      list-style-type: none;
      padding: 0;
    }
  }


  .invitation {
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    margin: 55px 0 5px;
    text-transform: uppercase;

    @include phone {
      margin-top: 20px;
    }
  }

  .remember-me {
    font-size: 21.319px;
    height: 22.5px;
    display: flex;
    justify-content: center;
    margin: 15px 0;

    label {
      height: 100%;
      text-align: center;
    }
  }

  .icon-email {
    @include icon('~annuity_lab/assets/icons/email_icon.svg', 20px);
  }

  .icon-password {
    @include icon('~annuity_lab/assets/icons/password_icon.svg', 18px);
  }

  .input-with-icon, .input-without-icon {
    width: 250px;
    margin: auto;
    margin-top: 5px;
    input {
      @include input-font-color($blueish);
      font-family: "Gotham Book";
    }
  }

  .icon-logo {
    width: 80px;
    height: 80px;
    background-color: white;
    border-radius: 8px;
    margin: 10px auto 0;

    @include phone {
      margin-top: 15px;
      margin-bottom: 15px;
    }
    @include icon('~annuity_lab/assets/icons/app_icon.svg', 70px);
  }
}
