@import '~common/styles/mixins';
@import '~common/styles/variables';

.new-comparison-plus-view {
  padding: 40px;

  @include phone {
    text-align: center;
    padding: 15px;
  }

  .error {
    color: $red;
  }

  .title {
    @include phone {
      font-size: 32px;
    }
  }

  .form-container {

    @include flex-rows;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    @include flex-wrap;

    .row-container {
      @include flex-cols;

      @include phone {
        @include flex-rows;

        height: initial;
        padding-top: 10px;
        padding-bottom: 10px;
      }

      .card-plus-select {
        -webkit-flex: 1;
        flex: 1;
        max-width: calc(50% - 12px);
        margin: 6px 6px;

        &:last-of-type {
          margin-top: 6px;
        }

        @include phone {
          max-width: 100%;

          margin: 0;

          &:last-of-type {
            margin-top: 10px;
          }
        }
      }

      &:first-child {
        margin-bottom: 2rem;
      }

      > .with-label {
        height: 4rem;
      }

      > .with-label {
        -webkit-flex: 1;
        flex: 1;
        max-width: calc(50% - 12px);
        margin: 6px 6px;

        @include phone {
          text-align: left;
          max-width: 100%;

          margin: 0;

          &:last-of-type {
            margin-top: 10px;
          }
        }
      }
    }
  }

  .single-select-container {
    width: 50%;
    padding-top: 20px;
    padding-bottom: 20px;

    @include phone {
      width: 100%;
      @include flex-fill;
      text-align: left;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  .btn-compare {
    margin-top: 30px;
    width: 100%;
    font-weight: bold;
  }
}
