@import '~common/styles/variables';
@import '~common/styles/mixins';

.with-tooltip {
  @include flex-cols;
  align-items: flex-end;

  .content {
    flex-grow: 1;

    margin: 0;
    padding: 0;
  }

  .info-icon {
    @include icon('~annuity_lab/assets/icons/info.svg', 15px);

    width: 15px;
    height: 15px;

    margin-bottom: 10px;
    margin-left: 5px;

    cursor: pointer;
  }

}
