@import '~common/styles/variables';

.fia-uploader {
  padding-bottom: 50px;

  .title {
    margin-bottom: 20px;
    padding: 10px 0;
    border-bottom: 2px solid $purple;
    color: $dark-blue;
    font-size: 27px;
  }

  .file-input {
    input {
      display: none;
    }

    label {
      width: 100%;
      background-color: $white;
      color: $blue;
      display: block;
      text-align: center;
      border-radius: 15px;
      padding: 10px;
      font-family: 'Gotham';
      font-weight: bold;
      cursor: pointer;
    }
  }

  .file-info {
    margin: 20px 0;
    font-weight: bold;

    .label {
      color: $medium-gray;
      font-size: 14px;
      text-transform: uppercase;
    }

    .file-name {
      color: $blue;
    }
  }

  .error {
    color: $red;
    font-weight: bold;
  }

  .upload-button {
    width: 100%;
    background-color: $blue;
    color: $white;
    display: block;
    text-align: center;
    border-radius: 15px;
    padding: 10px;
    font-family: 'Gotham';
    font-weight: bold;
    cursor: pointer;
    font-size: 18px;
  }

  .parser-errors {
    margin-top: 20px;
  }
}
