@import '~common/styles/variables';
@import '~common/styles/mixins';

$dialog-max-height: 90vh;

.confirm-dialog {
  max-height: $dialog-max-height;

  .header {
    background-color: $purple;
  }

  .body {
    @include flex-cols;
    overflow: auto;
    max-height: calc(#{$dialog-max-height} - 150px);
  }

  .footer {
    @include flex-cols;

    button {
      @include flex-fill;
    }

    button + button {
      margin-left: 10px;
    }
  }
}
