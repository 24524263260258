@import '~common/styles/mixins';
@import '~common/styles/variables';

.products-comparison-view {

  @include phone {
    > .comparison-chart,
    > .info-balloon.differential-balloon,
    > .info-balloon.hurdle-rate-balloon {
      display: none;
    }
  }


  .comparison-header {
    @include flex-cols;

    padding-top: 20px;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid $light-gray;

    @include phone {
      border: 0;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 10px;

      .comparison-controls {
        display: none;
      }
    }

    .name {
      @include flex-fill;

      @include ellipsis;

      font-size: 20px;
      color: $dark-gray;

      @include phone {
        text-align: center;
      }
    }
  }

  .product-context-inputs {
    margin-bottom: 30px;

    @include phone {
      display: none;
    }
  }


  > .comparison-disclaimer {
    font-size: 13px;
    margin: 10px 0;
    text-align: center;
    font-family: "Gotham Book";
  }
}
