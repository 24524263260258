@import '~common/styles/variables';
@import '~common/styles/mixins';

.chips {
  min-height: 36px;

  .chips-input::-webkit-input-placeholder {
    font-size: 13px;
    opacity: 0.5;
    font-style: italic;
  }

  .chips-input:-ms-input-placeholder {
    font-size: 13px;
    opacity: 0.5;
    font-style: italic;
  }
  .chips-input::-moz-placeholder {
    font-size: 13px;
    opacity: 0.5;
    font-style: italic;
  }
  .chips-input:-moz-placeholder {
    font-size: 13px;
    opacity: 0.5;
    font-style: italic;
  }

  .chips-input {
    display: inline-block;
    min-height: 36px;
    margin: 5px 0px;
    outline: none;
    border: 1px solid $light-gray;
  }

  .react-autosuggest__container {
    position: relative;
  }

  .react-autosuggest__suggestions-container--open {
    position: absolute;
    top: 38px;
    z-index: 1;
    background-color: $white;
    color: $blueish;
    width: 100%;
    border: 1px solid $light-gray;
    max-height: 150px;
    overflow-y: auto;
  }

  .react-autosuggest__suggestions-list {
    list-style: none;
    padding: 0;
  }

  .react-autosuggest__suggestion {
    padding: 10px;
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: $light-gray;
  }

  .chip {
    display: inline-block;
    margin: 5px 25px 5px 0px;
    position: relative;

    .chip-value {
      display: inline-block;
      padding: 5px 7.5px 5px 15px;
      background: $purple;
      color: $white;
      border-radius: 8px 0 0 8px;
    }

    .chip-delete-button {
      background: $purple;
      color: $white;
      border: 0;
      border-radius: 0 8px 8px 0;
      padding: 5px 10px;
      cursor: pointer;
      position: absolute;
      top: 0;
      bottom: 0;
      right: -25px;
      line-height: 0.5;
      font-weight: bold;
      margin-right: 5px;
    }
  }
}
