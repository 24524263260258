@import '~common/styles/variables';
@import '~common/styles/mixins';

.menu-select {
  .Select {
    &__control {
      height: 42px;
      line-height: 40px;
      text-align: center;
      background-color: $beige;
      color: $blue;
      cursor: pointer;
      border-radius: 0;
      border-bottom: none;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      margin-left: 3px;
      font-size: 13px;
      font-weight: bold;
      text-decoration: none;
      justify-content: center;

      .Select__value-container {
        padding: 0;
        padding-top: 2px;
        display: inline-flex;
        position: initial;
        flex: initial;
        flex-wrap: initial;

        .Select__single-value {
          color: $purple;
          position: initial;
          transform: none;
          margin: 0;
          text-overflow: initial;
          max-width: 100%;
        }

        .Select__placeholder {
          color: $blueish;
          text-overflow: clip;
          white-space: nowrap;
        }
      }

      .Select__indicators {
        width: 20px;
        height: 20px;
        margin-left: 10px;
        border-style: initial;
        border-width: 0px;
        @include icon('~annuity_lab/assets/icons/hamburger_icon.svg', 20px);
        background-repeat: 'no-repeat';
        background-size: 15px 15px;
        background-position: 'center';
      }
    }

    &__menu {
      top: 0;

      .Select__option {
        text-align: center;
      }
    }
  }
}