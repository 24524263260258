@import '~common/styles/mixins';
@import '~common/styles/variables';

.side-menu {
  @include flex-rows;

  overflow: auto;
  color: white;
  font-size: 32px;
  position: fixed;
  top: 0;
  bottom: 0;
  background-color: $dark-gray;

  .navigation {
    @include flex-rows;
    @include flex-value(1 0 auto);

    padding: 35px 27.5px;

    .app-logo {
      width: 90px;
      height: 90px;
      background-color: white;
      border-radius: 8px;
      @include icon('~annuity_lab/assets//icons/app_icon.svg', 80px);
      margin-bottom: 33px;
      align-self: center;
    }

    label {
      align-self: center;
      margin-bottom: 10px;
      color: $white;
      font-size: 10px;
      font-weight: bold;
      text-transform: uppercase;

      &.additional-resources {
        margin-top: 23px;
      }
    }

    a {
      text-decoration: none;
    }

    .btn {
      @include flex-rows;
      @include flex-justify-center;
      @include flex-align-center;
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 10px;
      text-align: center;
      padding: 9px 40px;
      min-height: 56px;
    }

    .btn.disabled {
      background-color: $less-dark-gray;
    }

    .external-link {
      padding: 15px 40px;
      border-top: 1px solid $medium-gray;
      color: $white;
      text-align: center;
      font-weight: bold;
      font-size: 16px;
    }
  }
}
