@import '~common/styles/variables';
@import '~common/styles/mixins';

$side-menu-width: 325px;
$header-height: 50px;

.tool-layout {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-x: hidden;

  .side-menu {
    width: $side-menu-width;
    transform: translateX(-100%);
  }

  .tool {
    width: 100%;
    height: 100%;
    @include flex-rows;

    .tool-header {
      height: $header-height;
      position: relative;
      width: 100%;
      color: $white;
      font-weight: bold;
      font-size: 16px;
      text-align: center;
      line-height: $header-height;
      vertical-align: middle;

      .menu-toggle-button {
        position: absolute;
        left: 15px;
        top: 11px;
        width: 30px;
        height: 30px;
        @include icon('~annuity_lab/assets//icons/annuity_nav_arrow_left.svg');
        cursor: pointer;
      }

      .menu-toggle-button.moved {
        @include icon('~annuity_lab/assets//icons/annuity_nav_arrow_right.svg');
      }

      @include phone {
        line-height: $header-height / 2;
        padding: 5px 20px 0 50px; // 50px so we have space for the menu icon
      }
    }

    .tool-base {
      height: 100%;

      @include mobile {
        @include scroll-y;
      }

      > .content {
        padding: 0 20px 0;
        @include phone {
          padding-left: 10px;
          padding-right: 10px;
        }
        box-sizing: border-box;
      }
    }
  }

  .side-menu.moved {
    transform: translateX(0);
  }

  .tool.moved {
    transform: translateX($side-menu-width);
    margin: 0 4px 20px;
  }
}
