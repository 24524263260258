@import '~common/styles/mixins';

.carry-forward-charts {
  .buttons-and-charts {
    @include flex-cols;
    @include flex-wrap;
    @include flex-space-around;

    position: relative;

    & > div {
      width: 24%;
    }

    .carry-forward {
      order: 0;
      cursor: initial;
    }

    .more-time {
      order: 5;

      &.selected {
        margin-bottom: 70px;

        @media only screen and (min-width: 991px) and (max-width: 1366px) {
          margin-bottom: 85px;
        }

        @include mobile {
          margin-bottom: 0;
        }
      }
    }

    .more-income {
      order: 10;

      &.selected {
        margin-bottom: 70px;

        @media only screen and (min-width: 991px) and (max-width: 1366px) {
          margin-bottom: 105px;
        }

        @include mobile {
          margin-bottom: 0;
        }
      }
    }

    .more-flexibility {
      order: 15;

      &.selected {
        margin-bottom: 85px;

        @media only screen and (min-width: 1060px) and (max-width: 1366px) {
          margin-bottom: 105px;
        }

        @media only screen and (min-width: 991px) and (max-width: 1059px) {
          margin-bottom: 145px;
        }

        @include mobile {
          margin-bottom: 0;
        }
      }
    }

    .more-time-chart,
    .more-income-chart,
    .more-flexibility-chart {
      flex-grow: 1;
      order: 20;
      width: 100%;
    }

    @include mobile {
      & > div {
        width: 100%;
        margin: 5px 0;
      }

      .more-time-chart {
        order: 7;
      }

      .more-income-chart {
        order: 12;
      }
    }
  }
}