@import '~common/styles/mixins';
@import '~common/styles/variables';

.imo-small-rider-card {
  @include flex-cols;
  @include flex-space-between;
  @include flex-align-center;

  background-color: $dark-red;
  color: $white;
  border-radius: $border-radius;
  padding: 6px;

  &.primary {
    background-color: $green;
  }

  .edit-card-button {
    border-radius: $border-radius;
    color: $blue;
    background-color: $white;
    font-weight: bold;
    padding-left: 12px;
    padding-right: 12px;
    height: 32px;
    font-size: 12px;
  }

}