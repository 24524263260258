@import '~common/styles/variables';

$imo-app-border-radius: 20px;

.imo-app {
  background-color: $beige;
  border-top-left-radius: $imo-app-border-radius;
  border-top-right-radius: $imo-app-border-radius;
  padding: 20px;

  max-width: 1440px;
  margin: 0 auto 20px;
}