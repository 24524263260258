@import '~common/styles/mixins';
@import '~common/styles/variables';

.info-balloon.differential-balloon {
  background-color: $green;

  padding: 15px;
  font-size: 24px;
  text-align: center;

  @include phone {
    font-size: 18px;
  }

  .highlighted {
    @include phone {
      display: block;
      font-size: 35px;
      font-weight: bold;
    }

    display: inline;
  }

  @include tablet {
    padding: 25px;
    font-size: 30px;
  }
}
