@import '~common/styles/variables';
@import '~common/styles/mixins';

.comparison-chart {
  position: relative;
  width: 500px;
  height: 190px;

  margin: 20px auto;

  .chart-grid,
  .columns {
    position: absolute;
    top: 5px;
    left: 0;
    right: 0;
    bottom: 35px;
    z-index: 0;
  }

  .columns {
    left: 40px;
  }

  @include tablet {
    .columns {
      left: 60px;
    }
  }

  .columns {
    @include flex-cols;
    @include flex-justify-center;
  }

  .chart-column:nth-child(1) {
    .active { background-color: $green; }
    .labels { color: $green; }
  }

  .chart-column:nth-child(2) {
    .active { background-color: $dark-red; }
    .labels { color: $dark-red; }
  }

  .chart-column:nth-child(3) {
    .active { background-color: $light-red; }
    .labels { color: $light-red; }
  }

  .chart-column {
    cursor: pointer;
  }

  .chart-column:first-child {
    cursor: auto;
  }

  .chart-column + .chart-column {
    margin-left: 7px;
  }
}
