@import '~common/styles/mixins';
@import '~common/styles/variables';

.comparison-card-summary {
  margin: 0 7px;
  text-align: center;

  .summary {
    margin-bottom: 10px;
    padding: 7px 0;
    border-radius: $small-border-radius;
    font-weight: bold;
    color: $white;

    @include flex-rows;
    @include flex-align-center;
    @include flex-justify-center;

    .difference-description {
      padding: 5px 0;
      font-size: 11px;
    }

    .difference-amount {
      font-size: 35px;
    }
  }

  .additional-text {
    margin-bottom: 10px;
    color: $dark-gray;

    font-size: 17px;
    line-height: 21px;

    span {
      font-weight: bold;
      color: $green;
    }
  }

  .additional-text.hidden {
    visibility: hidden;
    @include phone {
      display: none;
    }
  }
}

.comparison-card-summary.inline {
  @include flex-cols;
}

.comparison-card-summary.large-fonts {
  .summary {
    @include desktop {
      padding: 15px 0;
    }
  }

  .additional-text {
    padding: 20px 10%;
    font-size: 36px;
    line-height: 42px;

    @include mobile {
      font-size: 17px;
      line-height: 21px;
      padding: 10px;
    }
  }

  .difference-amount {
    font-size: 72px;

    @include mobile {
      font-size: 35px;
    }
  }

  .difference-description {
    font-size: 18px;

    @include mobile {
      font-size: 11px;
    }
  }
}
