@import '~common/styles/mixins';
@import '~common/styles/variables';

.products-comparison-view {

  @include phone {
    > .comparison-chart,
    > .info-balloon.differential-balloon,
    > .info-balloon.hurdle-rate-balloon {
      display: none;
    }
  }

  .comparison-header {
    @include flex-cols;

    padding-top: 20px;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid $light-gray;

    @include phone {
      border: 0;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 10px;

      .comparison-controls {
        display: none;
      }
    }

    @include tablet {
      .comparison-controls {
        display: none;
      }
    }

    .details {
      @include flex-fill;

      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 20px;
      color: $dark-gray;

      .comparison-name {
        font-weight: bold;
      }

      @include phone {
        text-align: center;
      }

    }
  }

  .product-context-inputs {
    margin-bottom: 30px;

    @include phone {
      display: none;
    }
  }
}
