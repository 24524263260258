@import '~common/styles/variables';
@import '~common/styles/mixins';

@mixin base-line-chart {
  padding: 4px;
  margin-top: 2rem;
  position: relative;

  .chart-start-balloon {
    background-color: $dark-gray;
    display: inline-block;
    position: absolute;
    z-index: 1;
    padding: 0 10px;
    border-radius: $small-border-radius;

    &::after {
      content: " ";
      position: absolute;
      top: 100%; /* At the bottom of the tooltip */
      left: 50%;
      margin-left: -6px;
      border-width: 6px;
      border-style: solid;
      border-color: $dark-gray transparent transparent transparent;
    }

    .start-text {
      font-size: 10px;
      font-weight: bold;
      color: white;
    }
  }

  .c3-chart-lines .c3-line {
    stroke-width: 4px;
  }

  .c3-grid line {
    stroke-dasharray: 0;
    stroke: $light-gray;
    stroke-width: 2px;
  }

  .c3-axis .tick text tspan {
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    fill: $dark-gray;
  }

  .c3-axis-x .tick line {
    display: none;
  }

  .c3-axis-y .tick line {
    stroke: $light-gray;
    stroke-width: 2px;
  }

  .c3-axis-x .domain {
    stroke: $light-gray;
  }

  @include mobile {
    .c3-axis .tick text tspan {
      font-size: 10px;
    }
  }
}

@mixin plus-carry-forward-chart {
  .title-container {
    .chart-title {
      display: inline-block;
      max-width: 100%;
      width: 100%;
      text-align: center;
      color: $less-dark-gray;
      font-weight: 400;
    }

    .chart-line-info {
      text-align: center;
      text-transform: uppercase;

      p {
        display: inline-block;
        font-size: 12px;
        margin: 0 10px 30px 10px;

        &::before {
          content: "";
          padding: 2px 8px;

          background-color: $blue;
          border-radius: 50%;

          margin-right: 5px;
        }
      }
    }
  }

  @include phone {
    .title-container {
      .chart-title {
        font-size: 22px;
      }

      .chart-line-info {
        p {
          font-size: 10px;

          &::before {
            padding: 1px 6px;
          }
        }
      }
    }
  }
}