@import '~common/styles/variables';
@import '~common/styles/mixins';

.card-plus {
  @mixin colored($color) {
    > .header {
      background-color: $color;
    }

    > .body .details,
    > .header .number {
      color: $color;
    }

    > .footer {
      background-color: $color;
      color: white;
    }
  }

  > .header {
    // FIXME DUplications
    .number {
      width: 24px;
      height: 24px;
      @include flex-center;

      @include phone {
        height: 16px;
        width: 16px;
        margin: 5px 10px 5px $content-base-left-padding-phone;
        // used for vertical centering, due to lack of better alternatives
        padding-top: 1.5px;
      }

      @include tablet {
        // used for vertical centering, due to lack of better alternatives
        padding-top: 1px;
      }

      margin: 10px 10px 10px $content-base-left-padding;

      background-color: white;
      border-radius: 50%;

      color: $dark-gray;
      line-height: 24px;
      text-align: center;
      vertical-align: middle;
    }

    .name {
      @include flex-fill;

      text-transform: uppercase;
      color: white;
      margin: 0;

      @include phone {
        // used for vertical centering, due to lack of better alternatives
        padding-top: 0.5px;
      }

      @include tablet {
        // used for vertical centering, due to lack of better alternatives
        padding-top: 1px;
      }

      @include tablet-landscape {
        // used for vertical centering, due to lack of better alternatives
        padding-top: 1px;
      }

    }

    .number,
    .name {
      font-size: 12px;
      font-weight: bold;

      @include phone {
        line-height: 12px;
        font-size: 12px;
      }

      @include tablet {
        line-height: 14px;
        font-size: 14px;
      }

      @include tablet-landscape {
        // used for vertical centering, due to lack of better alternatives
        padding-top: 1px;
      }
    }
  }

  > .body {
    padding: 20px 20px 0px 20px;

    @include phone {
      padding: 10px 0px 0px 0px;
    }

    .options {
      @include flex-rows;

      .selects-container {
        @include flex-cols;
        @include flex-wrap;

        @include phone {
          @include flex-space-between;
        }

        .with-label {
          @include desktop {
            @include flex-fill;
          }

          @include tablet {
            @include flex-fill;

          }
          @include phone {
            width: calc(50% - 2*#{$labeled-input-margins});
          }
        }

        .with-label {
          margin-left: $labeled-input-margins;
          margin-right: $labeled-input-margins;
        }
      }

      .withdrawl-values-container {
        @include flex-rows;
        @include flex-align-start;
        margin: 20px $labeled-input-margins;

        .with-label {
          @include flex-rows;
        }

        .withdrawal-values {
          background-color: $white;
          color: $blue;
          font-size: 1.2rem;
          font-weight: bold;
          padding: 4px;

          @include flex-cols;
          @include flex-align-end;
          // @include icon('~annuity_lab/assets//icons/white-plus.svg', 25px);
          // fill: $blue;

          .plus-icon {
            display: inline-block;
            width: 25px;
            height: 25px;
            // @include colored($blue, '~annuity_lab/assets//icons/white-plus.svg')
            @include icon('~annuity_lab/assets//icons/blue-plus.svg', 1.1rem);
            // fill: red;
          }

          @include phone {
            font-size: 1.1rem;
            padding: 4px 0;
          }
        }
      }
    }
  }

  > .footer {
    padding: 15px 20px 15px $content-base-left-padding;

    @include phone {
      padding: 10px 10px 10px $content-base-left-padding-phone;
    }

    .money-value {
      font-weight: bold;
    }

    .amount-needed {
      margin-left: 10px;
    }
  }

  &.card-evaluated:first-of-type {
    @include colored($green);
  }

  &.card-evaluated {
    @include colored($dark-red);
  }
}
