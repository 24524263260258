@import '~common/styles/variables';
@import '~common/styles/mixins';

.panel {
  .header {
    @include flex-cols;
    @include flex-align-center;

    background-color: $dark-gray;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;

    color: white;

    .close-button {
      width: 44px;
      height: 44px;

      margin-right: 5px;

      @include icon('~annuity_lab/assets//icons/delete.svg', 14px);

      @include phone {
        @include icon('~annuity_lab/assets//icons/delete.svg', 10px);
        height: 26px;
        width: 30px;
        padding: 10px 15px 10px 15px;
        margin-right: 0;
      }
    }
  }

  .body {
    padding: 20px;
    background-color: white;

    @include phone {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .footer {
    background-color: white;
    padding: 15px 20px;

    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;

    // box-shadow is used instead of border-top because we have other (left, right) borders on the
    // body and footer that must connect. Otherwise, they are being intersected by this border.
    box-shadow: inset 0 1px 0 $beige;
  }
}
