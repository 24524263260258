@import '~common/styles/mixins';
@import '~common/styles/variables';

@mixin colorize-option-card($color) {
  .title {
    background-color: $color;
    border: 2px solid $color;
  }

  .content {
    color: $color;
    border-left: 2px solid $color;
    border-right: 2px solid $color;
    border-bottom: 2px solid $color;
  }

  .arrow-up {
    border-bottom-color: $color;
  }

  .description {
    background-color: $color;
  }

  &.selected {
    .title {
      border-bottom: 2px solid $white;
    }

    .content {
      background-color: $color;
      color: $white;
    }

    @include mobile {
      .title {
        border-bottom: none;
      }

      .content {
        background-color: $white;
        color: $color;

        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
}

.option-button {
  cursor: pointer;

  .title {
    color: $white;
    text-align: center;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    padding: 8px 0 6px 0;
    font-weight: bold;
    font-size: 12px;
  }

  .content {
    min-height: 120px;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    background-color: $white;

    padding: 10px 0;

    @include flex-rows;
    @include flex-justify-center;
    @include flex-align-center;

    .main-text {
      font-weight: bold;
      font-size: 40px;
    }

    .top-text, .bottom-text {
      font-size: 10px;
      font-weight: bold;
      padding: 0 10px;
      text-align: center;
    }
  }

  .arrow-up {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid black;

    margin-top: 5px;

    position: relative;
    left: 48%;
  }

  .description {
    color: $white;
    font-size: 22px;
    font-weight: bold;
    text-align: center;

    width: 99%;
    position: absolute;
    left: 0;

    padding: 5px 15px;
    margin: 0 5px;
    border-radius: $border-radius;
  }

  &.dark-grey {
    @include colorize-option-card($dark-gray);
  }

  &.dark-red {
    @include colorize-option-card($dark-red);
  }

  &.purple {
    @include colorize-option-card($purple);
  }

  &.green {
    @include colorize-option-card($green);
  }

  @include mobile {
    &.selected {
      .content {
        border-bottom: 0;
      }
    }

    .content {
      .main-text {
        font-size: 55px;
      }

      .top-text,
      .bottom-text {
        font-size: 15px;
      }
    }

    .arrow-up {
      margin-top: -15px;
    }

    .description {
      position: static;
      width: 100%;
      margin: 0 0 10px 0;

      border-top-left-radius: 0;
      border-top-right-radius: 0;

      font-size: 26px;
    }
  }
}
