@import '~common/styles/variables';
@import '~common/styles/mixins';

@import '~common/styles/fonts';

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: $purple;

  // Prevents flickering on button clicks on some mobile devices:
  // https://github.com/JakeSidSmith/react-fastclick#notes
  -webkit-tap-highlight-color: transparent;
}

body {
  @include phone {
    padding: 0;
  }
}

* {
  box-sizing: border-box;
}

.application-root {
  width: 100%;
  height: 100%;
}

// Resets default button styles
button {
  margin: 0;

  border: none;
  border-radius: 0;
  background: none;
  color: inherit;
  line-height: normal;
  cursor: pointer;

  outline: none;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

// Default font-family
html, body,
h1, h2, h3, h4, h5, h6,
input, button {
  // TODO: Add alternatives if this is not available
  font-family: 'Gotham';
  font-weight: normal;
}

// Disable the default margins on the headigs
h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

h1 {
  font-size: 36px;
  color: $dark-gray;
  line-height: 135%;
}

h2 {
  font-size: 28px;
}

h3 {
  font-size: 24px;
}

@include tablet {
  h1 { font-size: 56px; }
  h2 { font-size: 32px; }
}

.btn {
  background-color: $blue;
  border-radius: $border-radius;

  outline: none;
  border: none;
  cursor: pointer;

  color: white;
  font-size: 13px;

  padding: 12px 15px;

  &.disabled {
    background-color: $medium-gray;
    cursor: auto;
  }
}

.btn-block {
  font-weight: bold;
  font-size: 14px;

  @include tablet {
    font-size: 24px;
    padding: 20px;
  }
}

input {
  margin: 0;
}

input[type="text"],
input[type="password"] {
  display: block;

  // IE 11 does not properly size input elements with `box-sizing: border-box`
  // This is why we need to account for the padding and border.
  width: calc(100% - 22px);

  font-size: 14px;

  @include tablet {
    font-size: 16px;
  }

  border-radius: $small-border-radius;
  border: 1px solid $light-gray;

  background-color: white;

  line-height: 20px;
  padding: 6px 10px;
  padding-top: 7px;

  height: 20px;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  box-sizing: content-box;

  // Prevent Firefox from displaying red shadow due to the `numeric` type
  &:invalid { box-shadow: none; }
}

@mixin tablet-layout {
  html,
  body,
  .application-root {
    height: 100%;
    overflow: hidden;
  }
}
@include tablet {
  @include tablet-layout;
}

@include tablet-landscape {
  @include tablet-layout;
}

input.error,
.Select.error .Select__control {
  border: 1px solid red;
}
