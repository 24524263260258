// Flexbox mixins
@import '~common/styles/variables';

@mixin flex-rows {
  display: -webkit-flex;
  display: flex;

  -webkit-flex-flow: column;
  flex-flow: column;
}

@mixin flex-cols {
  display: -webkit-flex;
  display: flex;

  -webkit-flex-flow: row;
  flex-flow: row;
}

@mixin flex-inline-cols {
  display: -webkit-inline-flex;
  display: inline-flex;

  -webkit-flex-flow: row;
  flex-flow: row;
}

@mixin flex-wrap {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

@mixin flex-value($value) {
  -webkit-flex: $value;
  flex: $value;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    flex-basis: auto;
  }
}

@mixin flex-fill {
  -webkit-flex: 1;
  flex: 1;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    flex-basis: auto;
  }
}

@mixin flex-no-shrink {
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}

@mixin flex-grow($value) {
  -webkit-flex-grow: $value;
  flex-grow: $value;
}

@mixin _flex-justify($value) {
  -webkit-justify-content: $value;
  justify-content: $value;
}

@mixin _flex-align($value) {
  -webkit-align-items: $value;
  align-items: $value;
}

@mixin flex-justify-center {
  @include _flex-justify(center);
}

@mixin flex-justify-start {
  @include _flex-justify(flex-start);
}

@mixin flex-justify-end {
  @include _flex-justify(flex-end);
}

@mixin flex-align-start {
  @include _flex-align(flex-start);
}

@mixin flex-align-center {
  @include _flex-align(center);
}

@mixin flex-align-stretch {
  @include _flex-align(stretch);
}

@mixin flex-align-end {
  @include _flex-align(flex-end);
}

@mixin flex-space-between {
  @include _flex-justify(space-between);
}

@mixin flex-space-around {
  @include _flex-justify(space-around);
}

@mixin flex-space-evenly {
  @include _flex-justify(space-evenly);
}

@mixin flex-align-self-start {
  -webkit-align-self: flex-start;
  align-self: flex-start;
}

@mixin flex-align-self-center {
  -webkit-align-self: center;
  align-self: center;
}

@mixin flex-align-self-end {
  -webkit-align-self: flex-end;
  align-self: flex-end;
}

@mixin flex-align-self-stretch {
  -webkit-align-self: flex-stretch;
  align-self: flex-stretch;
}

@mixin flex-center {
  @include flex-rows;
  @include flex-align-center;
  @include flex-justify-center;
}

// Others
@mixin input-font-color($value) {
  color: $blueish;

  // Change the color of the input placeholder
  &::-webkit-input-placeholder {
    color: $value;
  }

  &:-ms-input-placeholder {
    color: $value;
  }
}

@mixin ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin mobile-footer-controls {
  @include mobile {
    @include flex-fill;

    & > .controls {
      @include flex-fill;
      @include flex-cols;
      @include flex-align-center;
    }

    button.btn.btn-block {
      @include flex-fill;

      margin: 10px 5px 10px 0;
      padding-top: 11px;
      padding-bottom: 11px;

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  @include phone {
    margin: 0 11px;

    button.btn.btn-block {
      font-size: 14px;
    }
  }

  @include tablet {
    margin: 0 20px;

    button.btn.btn-block {
      font-size: 14px;
    }
  }

  @include tablet-landscape {
    margin: 0 20px;
  }
}

@mixin icon($url, $width: auto, $height: auto) {
  background-image: url($url);
  background-repeat: no-repeat;
  background-size: $width $height;
  background-position: center;
}

@mixin scroll-y {
  overflow-x: hidden;
  overflow-y: auto;

  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
}

@mixin card-color($color) {
  > .card .header {
    background-color: $color;
    color: $white;

    @include phone {
      border-radius: 0;
    }
  }

  .content {
    background-color: white;
  }

  > .card .body, > .card .remark, > .card .aside {
    color: $color;
  }

  > .card .summary {
    background-color: $color;
  }

  @include phone {
    border-color: $color;
    border-radius: 8px;
  }

  &.selected {
    .content {
      border-color: $color;
      @include phone {
        border: none;
      }
    }
  }

  .info-balloon {
    @include phone {
      border-radius: 0;
    }
  }

  .hint {
    color: $color;
    text-align: center;
    font-size: 14px;
    margin: 5px 0;
  }
}

@mixin card-color-plus($color) {
  > .card .header {
    background-color: $color;
    color: $white;

    @include phone {
      border-radius: 0;
    }
  }

  .content {
    background-color: white;
  }

  > .card .body, > .card .remark, > .card .aside {
    color: $color;
  }

  > .card .summary, > .card .balloon {
    background-color: $color;
  }

  @include phone {
    border-color: $color;
    border-radius: 8px;
  }

  .content {
    border-color: $color;
    @include phone {
      border: none;
    }
  }

  .footer {
    color: $color
  }

  .info-balloon {
    background-color: $color;
  }

  .info-balloon {
    @include phone {
      border-radius: 0;
    }
  }

  .hint {
    color: $color;
    text-align: center;
    font-size: 14px;
    margin: 5px 0;
  }
}

@mixin colorize-cards {
  .product:nth-child(1) {
    .small-product-card {
      @include card-color($green);
    }
  }

  .product:nth-child(2) {
    .small-product-card {
      @include card-color($dark-red);
    }
  }

  .product:nth-child(3) {
    .small-product-card {
      @include card-color($light-red);
    }
  }
}

@mixin colorize-cards-plus {
  .product:nth-child(1) {
    .small-product-card-plus {
      @include card-color-plus($green);
    }
  }

  .product:nth-child(2) {
    .small-product-card-plus {
      @include card-color-plus($dark-red);
    }
  }
}


// Animations and transitions

@mixin transition($args...) {
  -webkit-transition: $args;
  transition: $args;
}

@mixin animation-keyframes($name) {
  @-webkit-keyframes #{$name} { @content; }
  @keyframes #{$name} { @content; }
}

@mixin animation($name, $duration, $delay: 0s, $easing: ease-in-out) {
  -webkit-animation: $duration $easing $delay normal both $name;
  animation: $duration $easing $delay normal both $name;
}

// Media queries

@mixin tablet-landscape {
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (min-width:737px) and (max-width: 991px) {
    @content;
  }
}

@mixin phone {
  @media only screen and (max-width: 736px) {
    @content;
    -webkit-text-size-adjust: 100%;
  }
}

@mixin mobile {
  @include phone {
    @content;
  }

  @include tablet {
    @content;
  }

  @include tablet-landscape {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: 992px) {
    @content;
  }
}

@mixin page {
  width: 595px;
  height: 842px;
  padding: 0;
  position: relative;
  @media print {
    page-break-after: always;
    page-break-inside: avoid;
  }
}

@mixin report-button {
  display: inline-block;
  padding: 3px 8px 2px;
  background-color: $green;
  border-radius: 3px;
  color: $white;
  font-size: 9px;
  font-weight: bold;
  text-transform: uppercase;
}
