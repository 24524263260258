@import '~common/styles/mixins';
@import '~common/styles/variables';

div.checkbox label {
  line-height: initial;

  & > span.rc-checkbox  {
    height: 100%;
    border-radius: 5px;
    margin-right: 10px;
    position: relative;
    top: -2px;

    span.rc-checkbox-inner {
      border-width: 0;
      background-color: white;
      border-color: white;
      width: 23px;
      height: 100%;
    }

    &.rc-checkbox-checked {
      .rc-checkbox-inner:after {
        border-color: $blueish;
        border-bottom-width: 3px;
        border-right-width: 3px;
        height: 12px;
        width: 4px;
        left: 8px;
        top: 3px;
      }
    }
  }
}