@import '~common/styles/mixins';
@import '~common/styles/variables';

.info-balloon.hurdle-rate-balloon {
  margin-top: 10px;

  @include flex-cols;

  @include phone {
    @include flex-rows;
  }

  @include flex-align-center;
  @include flex-justify-center;

  .hurdle-rate {
    width: 250px;

    font-size: 50px;
    text-align: center;
    font-weight: bold;

    @include phone {
      font-size: 35px;
    }
  }

  .description {
    @include flex-fill;

    font-size: 16px;
    padding-right: 30px;

    @include phone {
      text-align: center;
      padding-right: 0;
      font-size: 18px;
    }
  }

  @include tablet {
    padding: 25px 0;

    .hurdle-rate {
      width: 300px;
      font-size: 56px;
    }

    .description {
      font-size: 20px;
    }
  }

  @mixin colored($color) {
    background-color: $color;

    .arrow.bottom {
      border-top-color: $color;
    }

    .arrow.top {
      border-bottom-color: $color;
    }
  }

  @include colored($dark-red);

  &.product-2 {
    @include colored($light-red);
  }
}
