@import '~common/styles/mixins';
@import '~common/styles/variables';

.menu.mad-menu {
  @include phone {
    padding-top: 6px;
  }

  @include flex-cols;

  a:not(:first-child) {
    margin-left: 3px;
  }

  a, .tab-select {
    @include flex-fill;
    max-width: 50%;
  }

  a {
    height: $menu-height;
    line-height: 42px;

    color: $blue;
    text-align: center;
    font-size: 13px;
    font-weight: bold;
    text-decoration: none;

    background-color: transparent;
    border: 2px solid $blue;
    border-bottom: none;
    border-radius: 0;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    margin-bottom: -1px;
    cursor: pointer;

    @include tablet {
      font-size: 16px;
    }
  }

  .tab-select {
    @include flex-fill;
  }

  a.active,
  .tab-select.active .Select__control {
    background-color: $beige;
    color: $purple;
    border-color: transparent;
  }
}
