@import '~common/styles/variables';
@import '~common/styles/mixins';
@import '~common/styles/charts';

.standard-model-view-chart {
  @include base-line-chart;

  .title-container {
    @include flex-cols;
    @include flex-justify-end;

    .chart-title {
      display: inline-block;
      max-width: 100%;
      width: 100%;
      text-align: center;
      color: $less-dark-gray;
      font-weight: 400;

      @include mobile {
        width: 90%;
        max-width: 90%;
        font-size: 18px;
      }

      @include phone {
        width: 70%;
        max-width: 70%;
        font-size: 14px;
      }
    }
  }
}
