@import '~common/styles/mixins';
@import '~common/styles/variables';

.rider-cards-controls {
  @include mobile-footer-controls;

  .controls {
    @include desktop {
      @include flex-rows;
    }

    @include tablet-landscape {
      @include flex-cols;
    }

    button {
      margin-top: 15px;
    }

    @include phone {
      .btn {
        padding-left: 5px;
        padding-right: 5px;
      }
    }

    .add-button {
      .plus-icon {
        display: inline-block;
        width: 15px;
        height: 15px;
        margin-bottom: -3px;
        margin-right: 5px;

        @include icon('~annuity_lab/assets/icons/white-plus.svg', 15px);

        @include tablet {
          margin-bottom: -2px;
          background-size: 20px auto;
        }

        @include phone {
          width: 14px;
          height: 14px;
          margin-bottom: -2px;
          background-size: 16px auto;
          margin-right: 3px;
        }
      }
    }

    .reset-button {
      background-color: $beige;
      color: $blue;
    }
  }
}
