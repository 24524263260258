@import '~common/styles/mixins';
@import '~common/styles/variables';

.product-differential-plus {
  width: 100%;

  > .hint {
    text-align: center;
    font-size: 17px;
    color: #666;

    margin-top: -10px;
    margin-bottom: 20px;
  }

  > .products {
    @include flex-cols;

    @include phone {
      @include flex-rows;
    }

    .product {
      @include flex-fill;

      border-radius: 8px;
      overflow: hidden;
      color: white;

      cursor: pointer;

      &.not-in-comparison {
        @include phone {
          order: 1;
        }
      }
    }

    .product + .product {
      margin-left: 15px;
    }

    @include phone {
      > .product + .product {
        margin-left: 0;
      }
    }

    .small-product-card-plus {
      @include tablet {
        .info-balloon, .hint {
          display: none;
        }
      }

      @include desktop {
        .info-balloon, .hint {
          display: none;
        }
      }
    }

    @include colorize-cards-plus;
  }

  > .disclaimer {
    font-size: 13px;
  }
}
