@import '~common/styles/mixins';

.with-label {
  label {
    font-size: 8px;
    font-weight: bold;
    text-transform: uppercase;

    @include tablet {
      font-size: 11px;
    }
  }
}
