@import '~common/styles/variables';
@import '~common/styles/mixins';

.product-context-inputs {
  @include flex-cols;
  @include flex-wrap;

  .with-label {
    max-width: calc(50% - 10px);
    color: $dark-gray;

    @include mobile {
      max-width: 100%;
    }

    margin: 0 10px;

    label {
      margin: 5px 0;
    }
  }
}
