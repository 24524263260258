@import '~common/styles/variables';
@import '~common/styles/mixins';
@import '~common/styles/charts';

.more-flexibility-chart {
  @include base-line-chart;
  @include plus-carry-forward-chart;

  .title-container {
    .chart-line-info {
      p:last-of-type {
        &::before {
          background-color: $green;
        }
      }
    }
  }

  .c3-event-rects {
    display: none;
  }

  .rect-below, .rect-below-2 {
    position: absolute;
  }

  .rect-below, .rect-below-2 {
    background-color: $blue;
    opacity: 0.6;
  }

  .tooltip-container, .tooltip-container-2 {
    $distance-from-left-border: 60px;
    $line-width: 2px;
    position: absolute;

    .line {
      height: 20px;
      width: $line-width;
      background-color: $green;
      margin-bottom: 16px;
      margin-left: $distance-from-left-border;
    }

    .tooltip {
      background-color: $green;
      display: inline-block;
      position: absolute;
      z-index: 1;
      padding: 6px 6px;
      border-radius: $small-border-radius;
      color: $white;
      font-size: 10px;
      font-weight: bold;
      width: 120px;

      &::after {
        content: " ";
        position: absolute;
        top: -12px; /* At the bottom of the tooltip */
        left: $distance-from-left-border + $line-width / 2;
        margin-left: -6px;
        border-width: 6px;
        border-style: solid;
        border-color:  transparent transparent $green transparent;
      }
    }
  }
}

