@import '~common/styles/variables';

.logout-button {
  padding: 16px 0 12px;
  color: $blue;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    color: $white;
  }
};
