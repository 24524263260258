@import '~common/styles/variables';
@import '~common/styles/mixins';

html, body {
  margin: 0;
  background-color: $purple;
}

.tool-wrapper {
  height: 100%;
  .layout {
    max-width: $site-max-width;
    margin: 0 auto 20px;
    overflow: auto;

    input {
      @include input-font-color($blueish);
    }

    .content {
      @include flex-rows;
    }

    @include mobile {
      margin-bottom: 0;
      max-height: initial;
    };

    .view-content {
      background-color: $beige;
      @include flex-fill;
      overflow: auto;

      .view-content-container {
        padding: 15px;

        @include phone {
          padding: 10px;
        }

        > .hidden {
          display: none;
        }
      }
    }

    @include tablet {
      @include flex-rows;

      .menu {
        @include flex-no-shrink;
      }

      .view-content {
        position: relative;

        @include flex-fill;
        @include scroll-y;

        .view-content-container {
          top: 0;
          width: 100%;
        }
      }
    }

    @include tablet-landscape {
      .view-content {
        @include scroll-y;
      }
    }
  }
}

.ReactModal__Body--open .application-root .tool-wrapper .tool-layout .tool-base {
  overflow: hidden;
}
