@import '~common/styles/mixins';
@import '~common/styles/variables';

.share-comparison-dialog {
  width: 600px;
  overflow-y: auto;
  overflow-x: hidden;

  @include phone {
    width: 359px;
    margin-top: 2px;
  }

  input, textarea {
    font-family: "Gotham Book";
    color: $blueish;
    font-size: 16px;
    -webkit-appearance: none;

    @include phone {
      font-size: 13px;
    }
  }

  &.panel .header {
    background-color: $purple;

    & h4 {
      font-size: 16px;

      @include phone {
        padding: 15px;
      }
    }
  }

  .share-form .footer {
    box-shadow: inset 0 1px 0 $light-gray;
    width: 100%;
    padding: 20px;

    @include phone {
      padding: 15px;
    }
  }

  &.panel .body {
    padding: 10px 20px;

    @include phone {
      padding: 7px 15px;
    }

    .invalid {
      input {
        border: 1px solid $red;
      }
    }
    


    input {
      @include input-font-color($blueish);
      width: 560px;
      height: 40px;
      border-radius: 10px;
      background-color: $white;
      padding: 8px;
      box-sizing: border-box;

      @include phone {
        width: 330px;
        border-radius: 6px;
      }
    }

    .subject-input {
      border: 1px solid $light-gray;
      -webkit-text-fill-color: $blueish;
      -webkit-opacity: 0.5;
      color: $blueish;
    }

    .sender-input {
      -webkit-text-fill-color: $blueish;
      -webkit-opacity: 0.5;
      color: $blueish;
      opacity: 0.5;
      border: 1px solid $light-gray;

    }

    .subject, .wholesaler-phone-number {
      margin: 4px 0px;
    }
  }

  label {
    font-size: 11px;
    color: $medium-gray;
    text-transform: uppercase;
    font-weight: bold;
    font-family: "Gotham";

    @include phone {
      font-size: 10px;
    }

    &.active {
      color: $gray;
    }
  }

  .body-input {
    -webkit-text-fill-color: $blueish;
    -webkit-opacity: 0.5;
    color: $blueish;
    border-radius: 10px;
    padding: 8px;
    height: 360px;
    width: 560px;
    border: 1px solid $light-gray;
    background-color: $white;
    resize: none;

    @include phone {
      height: 178px;
      width: 332px;
      border-radius: 6px;
    }
  }

  .body-area {
    background-color: $white;
    box-shadow: inset 0 1px 0 $light-gray;
    padding: 5px 20px 0;

    @include phone {
      padding: 5px 15px 0px;
    }
  }

  .comparison-name {
    background-color: $white;
    padding: 5px 20px;

    @include phone {
      padding: 5px 15px;
    }
  }

  .comparison-name-input {
    color: $blue;
    font-weight: bold;
    font-size: 14px;

    @include phone {
      font-size: 12px;
    }
  }

  .offline-message {
    font-size: 12px;
    padding: 10px;
    padding-top: 0;
    color: $red;
  }

  .share-button {
    width: 100%;

    @include phone {
      border-radius: 8px;
    }
  }

  .share-form {
    width: 600px;

    @include phone {
      width: 359px;
    }
  }

  .invalid-input-text {
    font-style: italic;
    font-size: 13px;
    color: red;
  }

  .wholesaler-name, .wholesaler-phone-number {
    .invalid-input-text {
      margin-top: 5px;
    }
  }

  .wholesaler-name-input::-webkit-input-placeholder {
    font-size: 12px;
    opacity: 0.5;
    font-style: italic;
  }

  .wholesaler-name-input:-ms-input-placeholder {
    font-size: 12px;
    opacity: 0.5;
    font-style: italic;
  }

  .wholesaler-name-input::-moz-placeholder {
    font-size: 12px;
    opacity: 0.5;
    font-style: italic;
  }

  .wholesaler-name-input:-moz-placeholder {
    font-size: 12px;
    opacity: 0.5;
    font-style: italic;
  }

  .wholesaler-phone-number-input::-webkit-input-placeholder {
    font-size: 12px;
    opacity: 0.5;
    font-style: italic;
  }

  .wholesaler-phone-number-input:-ms-input-placeholder {
    font-size: 12px;
    opacity: 0.5;
    font-style: italic;
  }

  .wholesaler-phone-number-input::-moz-placeholder {
    font-size: 12px;
    opacity: 0.5;
    font-style: italic;
  }

  .wholesaler-phone-number-input:-moz-placeholder {
    font-size: 12px;
    opacity: 0.5;
    font-style: italic;
  }
}
