@import '~common/styles/variables';
@import '~common/styles/mixins';

.chart-grid {
  .grid-step {
    position: absolute;
    width: 100%;
  }

  .label {
    position: absolute;
    top: -4px;
    left: 0;
    width: 40px;
    z-index: 1;

    color: $dark-gray;
    line-height: 10px;
    font-size: 9px;
    font-weight: bold;
  }

  .line {
    position: absolute;
    left: 40px;
    right: 0;
    height: 0;

    border-top: 1px solid $light-gray;
  }

  @include tablet {
    .label {
      font-size: 11px;
    }

    .line {
      left: 60px;
    }
  }
}
