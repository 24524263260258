@import '~common/styles/mixins';
@import '~common/styles/variables';

.products-comparison-plus-view {
  .comparison-header {
    @include flex-cols;

    padding-top: 20px;
    padding-bottom: 15px;
    margin-bottom: 15px;

    @include phone {
      border: 0;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 10px;

      .comparison-controls {
        display: none;
      }
    }

    .plus-name {
      @include flex-fill;

      font-size: 20px;
      color: $dark-gray;

      @include phone {
        text-align: center;
      }
    }
  }

  .product-context-inputs {
    margin-bottom: 30px;

    @include phone {
      display: none;
    }
  }
}
