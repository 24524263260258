@font-face {
  font-family: 'Gotham';

  src: url('~common/assets/fonts/gotham-bold.eot');
  src: url('~common/assets/fonts/gotham-bold.eot?#iefix') format('embedded-opentype'),
  url('~common/assets/fonts/gotham-bold.woff') format('woff'),
  url('~common/assets/fonts/gotham-bold.otf') format('opentype');

  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';

  src: url('~common/assets/fonts/gotham-light.eot');
  src: url('~common/assets/fonts/gotham-light.eot?#iefix') format('embedded-opentype'),
  url('~common/assets/fonts/gotham-light.woff') format('woff'),
  url('~common/assets/fonts/gotham-light.otf') format('opentype');

  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Book';

  src: url('~common/assets/fonts/gotham-book.eot');
  src: url('~common/assets/fonts/gotham-book.eot?#iefix') format('embedded-opentype'),
  url('~common/assets/fonts/gotham-book.woff') format('woff'),
  url('~common/assets/fonts/gotham-book.otf') format('opentype');

  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Extra Light';

  src: url('~common/assets/fonts/gotham-extra-light.eot');
  src: url('~common/assets/fonts/gotham-extra-light.eot?#iefix') format('embedded-opentype'),
  url('~common/assets/fonts/gotham-extra-light.woff') format('woff'),
  url('~common/assets/fonts/gotham-extra-light.otf') format('opentype');

  font-weight: normal;
  font-style: normal;
}
