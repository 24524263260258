@import '~common/styles/variables';
@import '~common/styles/mixins';

.chart-column {
  position: relative;

  width: 120px;

  text-align: center;

  // This container is here because of a bug in the flexbox implementation in iOS 8.
  // The problem on iOS 8 is that the height specified on `.active` and `.inactive`
  // is not considered and the columns get 0 height.
  .block-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    line-height: 0;

    @include flex-rows;
    @include flex-justify-end;
  }

  .active,
  .inactive {
    width: 100%;
    display: inline-block;
    transition: height 300ms ease-in-out;
    box-sizing: border-box;
  }

  .inactive {
    background-color: $light-gray;

    @include flex-rows;
    @include flex-justify-center;

    padding: 0 5px;
    line-height: 15px;
    font-size: 12px;
    text-align: center;

    color: $blue;

    overflow: hidden;
  }

  .labels {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;

    margin-top: 10px;
  }

  .label {
    font-size: 16px;
    font-weight: bold;
    line-height: 15px;
  }

  .subtext {
    font-size: 9px;
    font-weight: bold;
  }
}
