@import '~common/styles/variables';
@import '~common/styles/mixins';
@import '~common/styles/fonts';

.imo-carry-forward-view-table {
  overflow-x: auto;

  -webkit-overflow-scrolling: touch;

  $min-table-width: 739px;
  $percentage-small-table-column:  100 / ((2 * 1) + (3 * 2) + (3 * 4)); // We have 2 small, 3 medium, 3 large columns (5)
  $small-table-column-width: $min-table-width * $percentage-small-table-column / 100;
  $medium-table-column-width: $small-table-column-width * 2;
  $large-table-column-width: $small-table-column-width * 4;

  table {
    width: 100%;
    font-size: 13px;
    min-width: $min-table-width;
    border-collapse: collapse;
    border-spacing: 1px;

    @include mobile {
      font-size: 12px;
    }

    span {
      display: inline-block;
    }

    thead {
      font-weight: bold;

      th {
        text-align: center;
        padding-left: 8px;
        padding-right: 16px;
        font-size: 10px;
      }
    };

    tbody {
      tr {
        opacity: 0.5;

        &.highlighted {
          opacity: 1;
        }

        &:not(:first-child) {
          background-color: $white;
          border-bottom: 1px solid $beige;
        }

        td.small {
          width: $small-table-column-width;
        }

        td.medium {
          min-width: $medium-table-column-width;
        }

        td.large {
          min-width: $large-table-column-width;
        }
      }
    }

    td {
      font-family: 'Gotham Book';
      padding-right: 16px;
    }

    .two-column-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 4px;
    }

    .table-rider-name {
      color: $white;
      font-size: 10px;
      font-weight: bold;
      text-align: center;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
      padding: 4px 2px 2px 2px;

      @include mobile {
        font-size: 9px;
        display: inline-block;
        min-width: 65px;
      }
    }

    .standard-text {
      background-color: $green;
    }

    .carry-forward-text {
      background-color: $purple;
    }

    .money-container {
      text-align: right;
      padding-right: 4px;
      display: inline-block;
      width: 100%;
    }

    .rider-value {
      color: $white;
      padding-left: 4px;
      padding-top: 2px;
    }

    .data-container {
      min-width: $min-table-width * 4 / 100;
      padding-left: 4px;
    }
  }
}