@import '~common/styles/variables';
@import '~common/styles/mixins';

$element-and-border-offset: 8px;

.imo-summary-container {
  min-width: 550px;

  &.small {
    min-width: 400px;
  }

  .standard-summary-title {
    text-align: center;
    color: $less-dark-gray;
    font-weight: 400;

    @include mobile {
      font-size: 18px;
    }
  }

  .imo-standard-view-rider-card {
    min-width: 550px;
    display: inline-flex;
    @include flex-justify-center;
    @include flex-align-center;
    border-radius: $border-radius;
    font-weight: bold;
    width: 560px;
    margin-top: $element-and-border-offset;

    &.small {
      min-width: 400px;
      width: 400px;
    }

    @include mobile {
      width: 100%;
    }

    .small-text {
      font-size: 10px;
    }

    .name-container {
      @include flex-rows;
      text-align: center;
      padding: $element-and-border-offset;
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
      color: $white;
      border: 1px solid $dark-red;
      border-right: 1px solid $white;

      background-color: $dark-red;
    }

    .total-income-container {
      @include flex-rows;
      padding: $element-and-border-offset;
      color: $white;
      text-align: left;

      background-color: $dark-red;
      border: 1px solid $dark-red;
    }

    .income-container {
      @include flex-cols;
      @include flex-align-center;
      padding: $element-and-border-offset;
      color: $dark-red;
      background-color: $white;
      border: 1px solid $dark-red;
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
      text-align: left;

      .annual-income-container {
        @include flex-rows;

        margin-right: $element-and-border-offset;
        margin-left: $element-and-border-offset;
      }

      .age-container {
        @include flex-rows;
      }

      .then {
        margin-left: $element-and-border-offset;
        font-weight: normal;
      }
    }
  }


  .imo-standard-view-rider-card.primary {

    .name-container {
      background-color: $green;
      border: 1px solid $green;
      border-right: 1px solid $white;
    }

    .total-income-container {
      background-color: $green;
      border: 1px solid $green;
    }

    .income-container {
      color: $green;
      border: 1px solid $green;
    }
  }

}
